<template>
  <v-container fluid class="px-6 py-6">
    <v-row>
      <v-col
        md="6"
        cols="12"
        v-for="(item, index) in template_array"
        :key="index"
      >
        <v-card>
          <v-card-title class="d-flex justify-center"
            ><h2>{{ item.title }}</h2></v-card-title
          >
          <v-card-text class="d-flex justify-space-around">
            <a @click="downloadTemplates(item, false)"
              ><h1>下載預設{{ item.title }}</h1></a
            >
            <a v-if="item.customized" @click="downloadTemplates(item, true)"
              ><h1>下載客制{{ item.title }}</h1></a
            >
          </v-card-text>
          <v-card-text>
            <v-file-input
              :accept="`.${item.suffix}`"
              :label="`上傳${item.title}`"
              v-model="item.source"
            ></v-file-input
          ></v-card-text>
          <v-card-actions class="justify-space-around">
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                py-2
                px-6
                me-2
                bg-gradient-primary
              "
              color="#fff"
              small
              @click="uploadTemplates(item)"
              >上傳{{ item.title }}</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                py-2
                px-6
                me-2
                bg-gradient-default
              "
              color="#fff"
              small
              @click="resetTemplate(item.template_type)"
              >恢復預設{{ item.title }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import {
  object_type_enum,
  object_type_enum_str,
  case_template_type_enum,
  case_template_type_enum_str,
} from "@/case.js";

import { mapGetters } from "vuex";
export default {
  name: "LBKEY-Tools",
  data() {
    return {
      object_type_enum: object_type_enum,
      object_type_enum_str: object_type_enum_str,
      case_template_type_enum: case_template_type_enum,
      case_template_type_enum_str: case_template_type_enum_str,
      template_array: [
        {
          key: "building",
          title: "建物模板",
          source: null,
          template_type: case_template_type_enum.Building,
          customized: false,
          suffix: "docx",
        },
        {
          key: "land",
          title: "土地模板",
          source: null,
          template_type: case_template_type_enum.Land,
          customized: false,
          suffix: "docx",
        },
        {
          key: "building_rent",
          title: "建物租案",
          source: null,
          template_type: case_template_type_enum.BuildingRent,
          customized: false,
          suffix: "docx",
        },
        {
          key: "land_rent",
          title: "土地租案",
          source: null,
          template_type: case_template_type_enum.LandRent,
          customized: false,
          suffix: "docx",
        },
        {
          key: "cover",
          title: "封面(cover)",
          source: null,
          template_type: case_template_type_enum.Cover,
          customized: false,
          suffix: "docx",
        },
        {
          key: "watermark",
          title: "浮水印(PDF)",
          source: null,
          template_type: case_template_type_enum.WaterMark,
          customized: false,
          suffix: "pdf",
        },
        {
          key: "waterimage",
          title: "格局圖浮水印(png)",
          source: null,
          template_type: case_template_type_enum.WaterImage,
          customized: false,
          suffix: "png",
        },
      ],
    };
  },
  mixins: [HttpCommonMixin],
  mounted() {
    this.getCustomerFiles();
  },

  // mixins 要用正確的引用方式
  methods: {
    ...mapGetters(["getToken"]),
    getCustomerFiles() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/case-exports/customer-templates`;
      this.doHttpGet(url, null, this.getCustomerFilesDone);
    },
    getCustomerFilesDone(data) {
      console.log("data", data);
      // get attribute of data object
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          /* useful code here */
          let value = data[key];
          console.log(key + " -> " + data[key])
          // find the key in template_array and set value to the item in template_array
          let item = this.template_array.find((item) => item.key == key);
          if (item) {
            item.customized = value;
          }
        }
      }
    },

    uploadTemplates(item) {
      let template_type = item.template_type;
      let source = item.source;
      console.log("upload template type", template_type);
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/case-exports/upload-template`;
      const formData = new FormData();
      formData.append("template_type", template_type);
      formData.append("file", source);

      this.doHttpPost(url, formData, this.uploadDone);
    },
    uploadDone(data) {
      console.log("data:  ", data);
      this.getCustomerFiles();
      alert("上傳成功");
    },
    downloadTemplates(item, customized) {
      let template_type = item.template_type;
      let filename = item.title + "." + item.suffix;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/case-exports/download-template`;
      let params = {
        template_type: template_type,
        customized: customized,
      };
      if (customized) {
        filename = "客制" + filename;
      } else {
        filename = "預設" + filename;
      }
      console.log("download", url);
      this.doDownloadFile(url, params, filename);
    },
    resetTemplate(template_type) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/case-exports/delete-template/${template_type}`;
      this.doHttpDelete(url, this.deleteDone);
      // this.doHttpDeleteDataData(url, data, this.deleteDone);
      // delete-template
    },
    deleteDone(data) {
      console.log("data:  ", data);
      alert("刪除成功");
      this.getCustomerFiles();
    },
  },
};
</script>
